import React from "react";
import { TrustPilotProvider } from "./src/context/TrustPilotContext";
export const wrapRootElement = ({ element }) => {
  return <TrustPilotProvider>{element}</TrustPilotProvider>;
};

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const noReset = [
    "/faqs/loan-questions/",
    "/faqs/payment-options-questions/",
    "/faqs/credit-insurance-questions/",
    "/faqs/motor-club-questions/",
  ];

  window.history.scrollRestoration = "manual";
  if (noReset.indexOf(location.pathname) !== -1) {
  } else {
    window.scrollTo(0, 0);
  }
  return false;
};
