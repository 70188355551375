import { convertGps } from "../utils/utils";
import { Store } from "../types.d";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const getFilteredStores = (
  stores: Store[],
  lat: number,
  lng: number
) => {
  stores.filter(
    (store) =>
      convertGps(store.gPS ?? "").lat - lat < 0.36 &&
      convertGps(store.gPS ?? "").lat - lat > -0.36 &&
      convertGps(store.gPS ?? "").lng - lng < 0.36 &&
      convertGps(store.gPS ?? "").lng - lng > -0.36
  );
};

const toRad = (x: number) => {
  return (x * Math.PI) / 180;
};
const haversineDistance = (
  lat2: number,
  lon2: number,
  lat1: number,
  lon1: number
) => {
  const R = 6371; // km
  //has a problem with the .toRad() method below.
  const x1 = lat2 - lat1;
  const dLat = toRad(x1);
  const x2 = lon2 - lon1;
  const dLon = toRad(x2);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(lat1)) *
      Math.cos(toRad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  let d = R * c;
  d /= 1.60934;

  return d;
};
interface StoreWithDistance extends Store {
  distance: number;
}

export const getStoresInBounds = (
  stores: Store[],
  storesInView: Store[] | undefined,
  southWest: { lat: number; lng: number } | undefined,
  northEast: { lat: number; lng: number } | undefined,
  center: { lat: number; lng: number } | undefined
) => {
  const storesInViewTemp = stores
    .reduce((res, orig) => {
      const store = { ...orig };
      const gps = convertGps(`${store.latitude}, ${store.longitude}` ?? "");
      const lat = gps.lat;
      const lng = gps.lng;
      if (
        lat >= (southWest?.lat ?? 0) &&
        lat <= (northEast?.lat ?? 0) &&
        lng >= (southWest?.lng ?? 0) &&
        lng <= (northEast?.lng ?? 0)
      ) {
        res.push({
          ...store,
          distance: haversineDistance(
            gps.lat,
            gps.lng,
            center?.lat ?? 0,
            center?.lng ?? 0
          ),
        });
      }
      return res;
    }, [] as StoreWithDistance[])
    .sort((a, b) => (a.distance < b.distance ? -1 : 1));
  if (!storesInView || storesInView.length !== storesInViewTemp.length) {
    return storesInViewTemp;
  } else {
    for (let i = 0; i < storesInView.length; i++) {
      if (storesInView[i].id !== storesInViewTemp[i].id) {
        return storesInViewTemp;
      }
    }
  }
  return false;
};
