export const text = {
  title: {
    fontSize: 4,
    fontWeight: "bold",
    textTransform: "uppercase",
    color: "brand.navyBlue",
    lineHeight: 1.3,
  },
  whiteHero: {
    fontFamily: "heading",
    fontWeight: "body",
    lineHeight: "heading",
    color: "white",
    textAlign: "center",
  },
  heading: {
    fontFamily: "heading",
    fontWeight: "heading",
    lineHeight: "heading",
  },
  h1: {
    color: "primary",
    variant: "text.heading",
    textAlign: "center",
    fontWeight: 500,
    fontSize: ["36px", null, null, null, null, "45px"],
    lineHeight: 1.2,
  },
  h2: {
    color: "text",
    fontSize: [3, null, null, 4],
    fontWeight: "text",
    px: 2,
  },
  // h3 needs update
  h3: {
    width: "100%",
    fontSize: [20, null, null, null, 4],
    fontWeight: "bold",
    color: "white",
    textTransform: "uppercase",
    backgroundColor: "brand.tealSmallText",
    px: "10px",
    py: "5px",
    mb: "5px",
  },
  h4: {
    color: "text",
    fontSize: 2,
    lineHeight: 1.6,
    fontWeight: "heading",
    textAlign: "center",
  },
  paragraph: {
    maxWidth: "1200px",
    lineHeight: ["28px", null, null, "32px"],
    fontSize: [18, null, null, null, 22],
    color: "text",
    // fontSize: [2, null, null, null, 5],
    // lineHeight: 1.8,
    // letterSpacing: 0.5,
    // color: "text",
    // px: 2,
  },
  body: {
    fontFamily: "body",
    fontWeight: "body",
    lineHeight: "body",
  },
  disclaimer: {
    variant: "text.body",
    fontSize: [12, null, null, null, 12],
  },
  productH2: {
    color: "brand.darkBlue",
    fontSize: "26px",
    letterSpacing: "0.32px",
    lineHeight: "1.5",
    mb: "2",
  },
  productParagraph: {
    fontSize: "16px",
    lineHeight: 2,
    textAlign: "left",
  },
  link: {
    fontSize: 0,
    fontWeight: 500,
    textDecoration: "none",
    color: "brand.darkBlue",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
};
