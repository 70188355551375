module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Heights Finance","short_name":"Heights Finance","description":"Heights Finance Is There To Be Your Loan Professional. Personal Loans, Refinance, Or Debt Consolidations Available For When You Need It Most.","start_url":"/?utm_source=installed","lang":"en","theme_color":"#106CAA","display":"standalone","icon":"src/images/favicon.png","icon_options":{"purpose":"maskable badge any"},"categories":["finance","business"],"dir":"ltr","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e7a19bdc97efd9e0f7f453d1b74175ec"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.heightsfinance.com","stripQueryString":true},
    },{
      plugin: require('../../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-background-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MP4Q6JB","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../../cobra-find-a-store/gatsby-browser.js'),
      options: {"plugins":[],"brands":[2],"mapCenter":{"lat":39.9156575,"lng":-97.8569167},"mapZoom":4,"baseUrl":"https://www.heightsfinance.com","title":"Store Locations - Heights Finance","metaDescription":""},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
